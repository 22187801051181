import "core-js/modules/es.array.push.js";
import customerDevelopmentService from '@/services/statistics/customerDevelopmentService.js';
import customerDatatable from '@/components/statistics/customerDevelopment/customerDatatable.vue';
import customersGroups from '@/components/statistics/customerDevelopment/customersGroups.vue';
import showStores from '@/components/statistics/customerDevelopment/storeDevelopment.vue';
import offerService from '@/services/statistics/offerService.js';
import { mapGetters } from 'vuex';
export default {
  mounted() {
    this.customerDbRevenue();
    this.customerDbRevenue2();
    this.CustomerDocuments();
    this.selectedTotalCustomerDevelopmentOfFiveYear();
    //this.strongestCustomers()
    showStores;
    customerDatatable;
    customersGroups;
    this.showYears.call(this);
    this.totalDevelopmentNew(this);
  },
  props: {
    highestYearInFiveShownYears: {
      type: Number,
      required: true
    },
    newCustomer: {
      type: Array,
      required: true
    },
    totalCustomer: {
      type: Array,
      required: true
    },
    activeCustomer: {
      type: Array,
      required: true
    },
    inactiveCustomer: {
      type: Array,
      required: true
    },
    headLineText: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getYearStart', 'getYearEnd', 'getCustomerGroup', 'getLocations', 'getMinYear', 'getMinYear', 'getSelectedYear'])
  },
  data: function () {
    return {
      clickedRowIndex: null,
      addressTypesItems: [],
      datatableindex: 0,
      YEAR: 0,
      customerGroupsArray: [],
      selectedAddressType: [],
      yearEndSelection: new Date().getFullYear(),
      yearEndSelectionArticleType: new Date().getFullYear(),
      selectedGroup: [],
      shops: [],
      getYearsItems: [],
      search: '',
      yearStartSelection: new Date().getFullYear() - 4,
      yearStartSelectionArticleType: new Date().getFullYear() - 4,
      selected: [],
      testItems: [],
      selectedTest: [],
      selectBranche: [],
      totalyearcustomer: '',
      activeCustomer2: [],
      inactiveCustomer2: [],
      GraphYearYears: [],
      newCustomer2: [],
      articleindex: [],
      totalCustomer2: [],
      showMonthGraph: false,
      selectedYear: null,
      currentYear: null,
      db: [],
      type: '',
      revenue: [],
      kunden: [],
      kundendok: [],
      kundenverdok: [],
      switchstatus: false,
      strongestCustomers2: [],
      xAxisCategories: [],
      xAxisCategoriesCustomerDetails: [],
      seriesData: [],
      seriesDataCustomerDetails: [],
      dataforYear: [],
      customername: '',
      selectedCustomer: '',
      selectedTab: 'artikeltypen',
      customerId: '',
      AllCustomerId: [],
      AllCustomers: [],
      strongestCustomersYear: new Date().getFullYear() - 1,
      CustomerDetailsYear: new Date().getFullYear() - 1,
      AllCustomersList: [],
      AllSupplierList: [],
      selectedCustomerName: null,
      tmp: [],
      CustomerDetailsSelectedYear: '',
      isCollapsed: false,
      idfromxAxisCustomerDetails: [],
      idfromxAxisCustomerDetails2: [],
      dataPointIndexGraph: '',
      subkdnrCustomerDetails: [],
      fibu_kto: '',
      code: '',
      art_gruppe: '',
      art_kategorie: '',
      art_type: '',
      art_statcode: '',
      art_statgroup: '',
      CustomerDocumentsItems: [],
      CustomerDocumentsItems2: [],
      header2: [{
        text: 'Name',
        value: 'article.artBezeichung',
        align: 'start',
        sortable: true
      }, {
        text: 'Nummer',
        value: 'article.artNr',
        align: 'start',
        sortable: true
      }, {
        text: 'Umsatz',
        value: 'pos_netto',
        align: 'start',
        sortable: true
      }, {
        text: 'DB',
        value: 'db',
        align: 'start',
        sortable: true
      }, {
        text: 'Menge',
        value: 'menge',
        align: 'start',
        sortable: false
      }],
      header: [{
        text: 'Dokument',
        value: 'document',
        align: 'start',
        sortable: true
      }, {
        text: 'Datum',
        value: 'date',
        align: 'start',
        sortable: true
      }, {
        text: 'L-Termin',
        value: 'ldate',
        align: 'start',
        sortable: true
      }, {
        text: 'Dok.-Nr.',
        value: 'docNR',
        align: 'start',
        sortable: true
      }, {
        text: 'Position',
        value: 'position',
        align: 'start',
        sortable: false
      }, {
        text: 'Artikel-SM',
        value: 'articleSM',
        align: 'start',
        sortable: false
      }, {
        text: 'Saison',
        value: 'saison',
        align: 'start',
        sortable: false
      }, {
        text: 'Lokation',
        value: 'location',
        align: 'start',
        sortable: true
      }, {
        text: 'L-Menge',
        value: 'lQuantity',
        align: 'start',
        sortable: true
      }, {
        text: 'Rg-Menge',
        value: 'rqQuantity',
        align: 'start',
        sortable: true
      }, {
        text: 'ME',
        value: 'me',
        align: 'start',
        sortable: false
      }, {
        text: 'Nettopreis',
        value: 'netprice',
        align: 'start',
        sortable: true
      }, {
        text: 'Eigenanteil',
        value: 'ownContribution',
        align: 'start',
        sortable: true
      }],
      customerDetailsOptions: {
        chart: {
          height: 500,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: async (event, chartContext, config) => {
              const {
                seriesIndex,
                dataPointIndex
              } = config;
              this.CustomerDetails();
              if (this.tmp && this.tmp[seriesIndex]) {
                this.CustomerDetailsSelectedYear = this.tmp[seriesIndex].name;
                console.log('Selected Series Name:', this.CustomerDetailsSelectedYear);
                console.log(dataPointIndex);
              } else {
                console.log('Series Name not available or undefined.');
              }
              this.dataPointIndexGraph = dataPointIndex;
              const xAxisValue = this.xAxisCategoriesCustomerDetails[seriesIndex];
              console.log('Selected X-Axis Value:', xAxisValue);
              this.type = await this.test1(config.dataPointIndex);
              switch (this.selectedTab) {
                case 'artikelgruppe':
                  this.CustomerDocumentsbyGroup();
                  break;
                case 'artikeltypen':
                  this.CustomerDocuments();
                  break;
                case 'artikelkategorie':
                  this.CustomerDocumentsbyCategorie();
                  break;
                case 'statistikgruppen':
                  this.CustomerDocumentsbystatGroup();
                  break;
                case 'erloesgruppen':
                  this.CustomerDocumentsbyErloesgroup();
                  break;
                case 'statistikcode':
                  this.CustomerDocumentsbystatCode();
                  break;
              }
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: '12px'
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      strongestCustomersOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: async (event, chartContext, config) => {
              //this.$emit('showNewCustomers', config.dataPointIndex)

              var customer = await this.getCustomerbyIndex(config.dataPointIndex);
              this.selectedCustomer = customer;
              console.log(this.selectedCustomer);
              this.CustomerDetails();
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      customerDbRevenueOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              console.log();
              var selectedBarIndex = config.dataPointIndex;
              if (selectedBarIndex == -1) {
                console.log('');
              } else {
                var year = this.yearStartSelection + selectedBarIndex;
              }
              if (this.YEAR == year) {
                this.YEAR = 0;
              } else {
                this.YEAR = year;
              }
              this.strongestCustomers();
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              const formatter = new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
              return formatter.format(value).replace(/\s/g, '');
            }
          }
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      customerDocumentOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              console.log();
              var selectedBarIndex = config.dataPointIndex;
              if (selectedBarIndex == -1) {
                console.log();
              } else {
                var year = this.yearStartSelection + selectedBarIndex;
              }
              if (this.YEAR == year) {
                this.YEAR = 0;
              } else {
                this.YEAR = year;
              }
              this.strongestCustomers();
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        yaxis: {},
        noData: {
          text: 'Keine Daten verfügbar'
        }
      },
      totalChartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
          events: {
            click: (event, chartContext, config) => {
              var selectedBarIndex = config.dataPointIndex;
              if (selectedBarIndex == -1) {
                console.log();
              } else {
                var year = this.yearStartSelection + selectedBarIndex;
              }
              if (this.YEAR == year) {
                this.YEAR = 0;
              } else {
                this.YEAR = year;
              }
              this.strongestCustomers();
            }
          }
        },
        dataLabels: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          }
        },
        xaxis: {
          categories: []
        },
        noData: {
          text: 'Keine Daten verfügbar'
        }
      }
    };
  },
  created() {
    this.CustomerDetails();
    //this.CustomerDocuments()
    this.strongestCustomers();
    this.showAddressTypes();
    this.showCustomerGroups();
    this.showStores(this);
    this.showYears();
    this.AllCustomersList = this.ListOfAllPersons('consumer');
    this.AllSupplierList = this.ListOfAllPersons('supplier');
    this.generateYearsOptions();
    this.selectedTotalCustomerDevelopmentOfFiveYear();
    this.customerDbRevenue();
    this.customerDbRevenue2();
    this.selectedTotalCustomerDevelopmentOfYear();
  },
  beforeMount() {
    this.customerDbRevenue();
    this.customerDbRevenue2();
    this.CustomerDetails();
    this.selectedTotalCustomerDevelopmentOfFiveYear();
    this.showStores();
    this.showYears();
    this.AllCustomersList = this.ListOfAllPersons('consumer');
    this.AllSupplierList = this.ListOfAllPersons('supplier');
    this.selectedTotalCustomerDevelopmentOfYear();
  },
  methods: {
    handleRowClick(item) {
      const index = this.CustomerDocumentsItems2.findIndex(element => element === item);
      console.log('Index des ausgewählten Elements:', index);
      console.log(item.article.artNr);
      this.art_nr = item.article.artNr;
      this.CustomerDocuments2();
      // console.log("bin i's gleiche? " + this.art_nr)
      //this.getArticlesbyType2()
    },
    async test1(index) {
      return this.articleindex[index];
      // this.getArticlesbyType()
    },
    updateSelectedYear(selectedYearIndex) {
      if (this.GraphYearYears[selectedYearIndex]) {
        this.yearStartSelection = this.GraphYearYears[selectedYearIndex];
        this.yearEndSelection = this.GraphYearYears[selectedYearIndex];
        this.test();
      }
    },
    async getCustomerbyIndex(i) {
      if (this.switchstatus == true) {
        if (this.YEAR == 0) {
          try {
            const response = await customerDevelopmentService.getStrongestCustomers2(this.strongestCustomersYear - 1, this.strongestCustomersYear + 1, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10);
            const number = response.data.users[i].subkdnr;
            return number;
          } catch (error) {
            console.error(error);
            throw error;
          }
        } else {
          try {
            const response = await customerDevelopmentService.getStrongestCustomers2(this.YEAR, this.YEAR, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10);
            const number = response.data.users[i].subkdnr;
            return number;
          } catch (error) {
            console.error(error);
            throw error;
          }
        }
      } else {
        if (this.YEAR == 0) {
          try {
            const response = await customerDevelopmentService.getStrongestCustomers(this.strongestCustomersYear - 1, this.strongestCustomersYear + 1, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10);
            const number = response.data.users[i].subkdnr;
            return number;
          } catch (error) {
            console.error(error);
            throw error;
          }
        } else {
          try {
            const response = await customerDevelopmentService.getStrongestCustomers(this.YEAR, this.YEAR, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10);
            const number = response.data.users[i].subkdnr;
            return number;
          } catch (error) {
            console.error(error);
            throw error;
          }
        }
      }
    },
    changeswitch() {
      if (this.switchstatus == false) {
        this.switchstatus = true;
      } else {
        this.switchstatus = false;
      }
      this.strongestCustomers();
      console.log(this.switchstatus);
    },
    toggleFilters() {
      this.isCollapsed = !this.isCollapsed;
    },
    test() {
      this.selectedTotalCustomerDevelopmentOfFiveYear();
      this.CustomerDetails();
      this.strongestCustomers();
      this.customerDbRevenue();
      this.customerDbRevenue2();
      this.selectedAddressTypes();
      this.selectedCustomerGroups();
      this.selectedBranches();
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
    },
    TabSelect(evt, name) {
      this.selectedTab = name;
      this.CustomerDetails();
      const tabcontent = document.getElementsByClassName('tabcontent');
      for (let i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = 'none';
      }
      const tablinks = document.getElementsByClassName('tablinks');
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
      }
      document.getElementById(name).style.display = 'block';
      evt.currentTarget.className += ' active';
    },
    showCustomerGroups() {
      customerDevelopmentService.getListOfAllCustomerGroups().then(response => {
        response.data.forEach(customerGroup => {
          this.customerGroupsArray.push(customerGroup);
        });
      });
    },
    generateYearsOptions() {
      let currentYear = new Date().getFullYear();
      let options = [];
      for (let i = currentYear; i >= 2010; i--) {
        options.push({
          text: i.toString(),
          value: i
        });
      }
      return options;
    },
    showAddressTypes() {
      customerDevelopmentService.getListOfAllAddressTypes().then(response => {
        response.data.forEach(customerGroup => {
          this.addressTypesItems.push(customerGroup);
        });
      });
    },
    showStores() {
      customerDevelopmentService.getDetaillisteOfAllStores().then(response => {
        this.shops = response.data.map(items => ({
          id: items.id,
          name: items.description
        }));
      });
    },
    showYears() {
      offerService.getOffersForYearSpan().then(response => {
        response.data.forEach(year => {
          this.getYearsItems.push(year);
        });
      });
    },
    CustomerDocuments() {
      this.CustomerDocumentsItems = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbyType(this.type
      /*this.art_kategorie,
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
      this.fibu_kto,
      this.code,
      this.art_gruppe,
      this.art_type,
      this.art_statcode*/).then(response => {
        this.CustomerDocumentsItems = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    CustomerDocuments2() {
      this.CustomerDocumentsItems2 = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbyType2(
      // this.art_kategorie,
      this.art_nr, '1900', '2090'
      /*
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
      this.fibu_kto,
      this.code,
      this.art_gruppe,
      this.art_type,
      this.art_statcode*/).then(response => {
        this.CustomerDocumentsItems2 = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    CustomerDocumentsbyCategorie() {
      this.CustomerDocumentsItems = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbyCategorie(this.art_kategorie
      /*this.art_kategorie,
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
      this.fibu_kto,
      this.code,
      this.art_gruppe,
      this.art_type,
      this.art_statcode*/).then(response => {
        this.CustomerDocumentsItems = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    CustomerDocumentsbyGroup() {
      this.CustomerDocumentsItems = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbyGroup(this.art_gruppe
      /*this.art_kategorie,
      this.art_kategorie,
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
      this.fibu_kto,
      this.code,
      this.art_type,
      this.art_statcode*/).then(response => {
        this.CustomerDocumentsItems = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    CustomerDocumentsbystatCode() {
      this.CustomerDocumentsItems = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbystatCode(this.art_statcode
      //this.art_gruppe,
      /*this.art_kategorie,
      this.art_kategorie,
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
      this.fibu_kto,
      this.code,
      this.art_type,
      */).then(response => {
        this.CustomerDocumentsItems = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    CustomerDocumentsbyErloesgroup() {
      this.CustomerDocumentsItems = [];
      console.log(this.idfromxAxisCustomerDetails);
      customerDevelopmentService.getArticlesbyerloesgruppe(this.fibu_kto
      //this.art_statgroup
      //this.art_gruppe,
      /*this.art_kategorie,
      this.art_kategorie,
      this.selectedCustomer,
      this.CustomerDetailsSelectedYear,
      this.CustomerDetailsSelectedYear,
             this.code,
      this.art_type,
      */).then(response => {
        this.CustomerDocumentsItems = response.data;
        console.log(response.data);
        this.CustomerDetails();
      }).catch(error => {
        console.error('Fehler bei der Abfrage der Kundendokumente:', error);
      });
    },
    selectedTotalCustomerDevelopmentOfYear() {
      /*year,
      locations,
      addressType,
      customerGroup*/

      customerDevelopmentService.getTotalCustomerDevelopmentOfYear(this.getSelectedYear, this.selectBranche, this.selectedAddressType, this.selectedGroup).then(response => {
        const yearData = response.data.find(year => year.year === this.yearStartSelection);
        this.activeCustomer2 = [yearData.activeCustomer];
        this.inactiveCustomer2 = [yearData.inactiveCustomer];
        this.totalCustomer2 = [yearData.totalCustomer];
        this.newCustomer2 = [yearData.newCustomer];
        this.updateChart();
      }).catch(error => {
        console.error(error);
      });
    },
    selectedTotalCustomerDevelopmentOfFiveYear() {
      customerDevelopmentService.getTotalCustomerDevelopmentOfFiveYear(this.yearStartSelection, this.yearEndSelection, this.selectBranche, this.selectedAddressType, this.selectedGroup).then(response => {
        response.data.forEach(year => {
          this.GraphYearYears.push(year.year);
          this.activeCustomer2.push(year.activeCustomer);
          this.inactiveCustomer2.push(year.inactiveCustomer);
          this.totalCustomer2.push(year.totalCustomer);
          this.newCustomer2.push(year.newCustomer);
        });
        this.updateChart();
      }).catch(error => {
        console.error(error);
      });
    },
    CustomerDetails() {
      this.selectedCustomerName = this.selectedCustomer;
      customerDevelopmentService.getCustomerDetailsType(2010, this.yearEndSelectionArticleType, this.selectedCustomer, 'consumer', this.selectedTab).then(response => {
        response.data.users.forEach(user => {
          const xAxisCategory = {
            name: user.name,
            subkdnr: user.subkdnr
          };
          this.xAxisCategoriesCustomerDetails.push(xAxisCategory.name);
          this.idfromxAxisCustomerDetails.push(xAxisCategory.subkdnr);
        });
        response.data.yearsData.forEach(yearData => {
          this.seriesDataCustomerDetails.push(yearData);
        });
        console.log(this.idfromxAxisCustomerDetails[0]);
        this.articleindex = [...this.idfromxAxisCustomerDetails];
        this.updateChart4();
      }).catch(error => {
        console.error(error);
      });
    },
    strongestCustomers() {
      if (this.YEAR != 0) {
        this.strongestCustomersyearmethod(this.YEAR);
      } else {
        this.checkstrongestCustomers();
      }
    },
    checkstrongestCustomers() {
      if (this.switchstatus == false) {
        customerDevelopmentService.getStrongestCustomers(this.strongestCustomersYear - 1, this.strongestCustomersYear + 1, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10).then(response => {
          console.log(response.data);
          response.data.users.forEach(user => {
            this.xAxisCategories.push(user.name);
          });
          this.AllCustomers = this.xAxisCategories;
          response.data.users.forEach(id => {
            this.AllCustomerId.push(id.subkdnr);
          });
          response.data.yearsData.forEach(yearData => {
            this.seriesData.push(yearData);
          });
          this.updateChart3();
        }).catch(error => {
          console.error(error);
        });
      } else {
        customerDevelopmentService.getStrongestCustomers2(this.strongestCustomersYear - 1, this.strongestCustomersYear + 1, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10).then(response => {
          console.log(response.data);
          response.data.users.forEach(user => {
            this.xAxisCategories.push(user.name);
          });
          this.AllCustomers = this.xAxisCategories;
          response.data.users.forEach(id => {
            this.AllCustomerId.push(id.subkdnr);
          });
          response.data.yearsData.forEach(yearData => {
            this.seriesData.push(yearData);
          });
          this.updateChart3();
        }).catch(error => {
          console.error(error);
        });
      }
    },
    strongestCustomersyearmethod(year) {
      if (this.switchstatus == false) {
        customerDevelopmentService.getStrongestCustomers(year, year, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10).then(response => {
          console.log(response.data);
          this.customername = response.data;
          response.data.users.forEach(user => {
            this.xAxisCategories.push(user.name);
          });
          this.AllCustomers = this.xAxisCategories;
          response.data.users.forEach(id => {
            this.AllCustomerId.push(id.subkdnr);
          });
          response.data.yearsData.forEach(yearData => {
            this.seriesData.push(yearData);
          });
          this.updateChart3year(year);
        }).catch(error => {
          console.error(error);
        });
        this.YEAR = year;
      } else {
        customerDevelopmentService.getStrongestCustomers2(year, year, this.selectBranche, this.selectedAddressType, this.selectedGroup, 10).then(response => {
          console.log(response.data);
          response.data.users.forEach(user => {
            this.xAxisCategories.push(user.name);
          });
          this.AllCustomers = this.xAxisCategories;
          response.data.users.forEach(id => {
            this.AllCustomerId.push(id.subkdnr);
          });
          response.data.yearsData.forEach(yearData => {
            this.seriesData.push(yearData);
          });
          this.updateChart3year(year);
        }).catch(error => {
          console.error(error);
        });
      }
      this.YEAR = year;
    },
    ListOfAllPersons(type) {
      let list = [];
      customerDevelopmentService.getAllPersonList(type).then(response => {
        response.data.forEach(persons => {
          list.push(persons);
        });
      });
      return list;
    },
    customerDbRevenue() {
      customerDevelopmentService.getDBPosNetfromCustomer(this.yearStartSelection, this.yearEndSelection, this.selectBranche, this.selectedAddressType, this.selectedGroup).then(response => {
        response.data.forEach(year => {
          this.revenue.push(parseFloat(year.pos_netto).toFixed(2));
          this.db.push(parseFloat(year.db).toFixed(2));
          this.kunden.push(parseFloat(year.kunden));
        });
        this.updateChart2();
      }).catch(error => {
        console.error(error);
      });
    },
    customerDbRevenue2() {
      customerDevelopmentService.getDBPosNetfromCustomer2(this.yearStartSelection, this.yearEndSelection, this.selectBranche, this.selectedAddressType, this.selectedGroup).then(response => {
        response.data.forEach(year => {
          this.kundendok.push(parseFloat(year.amount).toFixed(2));
          this.kundenverdok.push(parseFloat(year.amountVerr).toFixed(2));
        });
        this.updateChart1921();
      }).catch(error => {
        console.error(error);
      });
    },
    selectedCustomerGroups() {
      this.$store.dispatch('setCustomerGroup', this.selectedGroup);
      this.selectedTotalCustomerDevelopmentOfFiveYear();
    },
    selectedAddressTypes() {
      this.$store.dispatch('setAddressType', this.selectedAddressType);
      this.selectedTotalCustomerDevelopmentOfFiveYear();
    },
    selectedBranches() {
      this.$store.dispatch('setLocations', this.selectBranche);
      this.selectedTotalCustomerDevelopmentOfFiveYear();
    },
    updateChart4() {
      this.customerDetailsOptions.xaxis.categories = this.xAxisCategoriesCustomerDetails;
      let tmp = [];
      this.seriesDataCustomerDetails.forEach(y => {
        if (y.year >= this.CustomerDetailsYear - 1 && y.year <= this.CustomerDetailsYear + 1) tmp.push({
          name: y.year,
          data: y.data
        });
      });
      this.tmp = tmp;
      this.$refs.totalChart4.updateSeries(tmp), this.$refs.totalChart4.updateOptions({
        xaxis: {
          categories: this.xAxisCategoriesCustomerDetails
        }
      });
      this.seriesDataCustomerDetails = [];
      this.xAxisCategoriesCustomerDetails = [];
      this.fibu_kto = '';
      this.code = '';
      this.art_gruppe = '';
      this.art_kategorie = '';
      this.art_type = '';
      this.art_statcode = '';
      this.art_statgroup = '';
      switch (this.selectedTab) {
        case 'artikelgruppe':
          this.art_gruppe = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
        case 'artikeltypen':
          this.art_type = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
        case 'artikelkategorie':
          this.art_kategorie = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
        case 'statistikgruppen':
          this.art_statgroup = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
        case 'erloesgruppen':
          this.fibu_kto = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
        case 'statistikcode':
          this.art_statcode = this.idfromxAxisCustomerDetails[this.dataPointIndexGraph];
          break;
      }
      this.idfromxAxisCustomerDetails = this.subkdnrCustomerDetails;
      this.idfromxAxisCustomerDetails = [];
    },
    updateChart3() {
      this.strongestCustomersOptions.xaxis.categories = this.xAxisCategories;
      let tmp = [];
      this.seriesData.forEach(y => {
        if (y.year >= this.strongestCustomersYear - 1 && y.year <= this.strongestCustomersYear + 1) tmp.push({
          name: y.year,
          data: y.data
        });
      });
      this.$refs.totalChart3.updateSeries(tmp), this.$refs.totalChart3.updateOptions({
        xaxis: {
          categories: this.xAxisCategories
        }
      });
      this.seriesData = [];
      this.xAxisCategories = [];
    },
    updateChart3year(year) {
      this.strongestCustomersOptions.xaxis.categories = this.xAxisCategories;
      let tmp = [];
      this.seriesData.forEach(y => {
        if (y.year >= year - 1 && y.year <= year + 1) tmp.push({
          name: y.year,
          data: y.data
        });
      });
      this.$refs.totalChart3.updateSeries(tmp), this.$refs.totalChart3.updateOptions({
        xaxis: {
          categories: this.xAxisCategories
        }
      });
      this.seriesData = [];
      this.xAxisCategories = [];
    },
    updateChart2() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.customerDbRevenueOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart2.updateSeries([{
        name: 'Deckungsbetrag',
        data: this.db
      }, {
        name: 'Einnahmen',
        data: this.revenue
      }]), this.$refs.totalChart2.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.db = [];
      this.revenue = [];
    },
    updateChart1921() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.customerDocumentOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart1921.updateSeries([{
        name: 'in Dokument',
        data: this.kundendok
      }, {
        name: 'in Verrechnungsdokument',
        data: this.kundenverdok
      }]), this.$refs.totalChart1921.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.kundendok = [];
      this.kundenverdok = [];
    },
    updateChart() {
      const startYear = this.yearStartSelection;
      const endYear = this.yearEndSelection;
      const xAxisCategories = [];
      for (let year = startYear; year <= endYear; year++) {
        xAxisCategories.push(year.toString());
      }
      this.totalChartOptions.xaxis.categories = xAxisCategories;
      this.$refs.totalChart.updateSeries([{
        name: 'Gesamt',
        data: this.totalCustomer2
      }]), this.$refs.totalChart.updateOptions({
        xaxis: {
          categories: xAxisCategories
        }
      });
      this.totalCustomer2 = [];
    },
    yearStartSelectionArticleTypeChange(selected) {
      this.$store.dispatch('setYearStart', selected);
      this.$store.dispatch('setMonthly', false);
      this.CustomerDetails();
    },
    yearEndSelectionChangeArticleType(selected) {
      this.$store.dispatch('setYearEnd', selected);
      this.$store.dispatch('setMonthly', false);
      this.CustomerDetails();
    },
    yearStartSelectionChange(selected) {
      this.$store.dispatch('setYearStart', selected);
      this.$store.dispatch('setMonthly', false);
      this.selectedTotalCustomerDevelopmentOfFiveYear();
    },
    yearEndSelectionChange(selected) {
      this.$store.dispatch('setYearEnd', selected);
      this.$store.dispatch('setMonthly', false);
      this.selectedTotalCustomerDevelopmentOfFiveYear();
    },
    checkifYEARisset() {
      if (this.YEAR == 0) {
        return false;
      } else return true;
    },
    yearBack1() {
      let yearStart = this.yearStartSelection;
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1);
        }
        this.yearStartSelection = yearStart - 1;
        this.yearEndSelection -= 1;
        this.selectedTotalCustomerDevelopmentOfFiveYear();
      }
    },
    yearForward1() {
      let yearEnd = this.yearEndSelection;
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1);
        }
        this.yearStartSelection += 1;
        this.yearEndSelection = yearEnd + 1;
        this.selectedTotalCustomerDevelopmentOfFiveYear();
      }
    },
    yearBack2() {
      let yearStart = this.yearStartSelection;
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1);
        }
        this.yearStartSelection = yearStart - 1;
        this.yearEndSelection -= 1;
        this.customerDbRevenue();
      }
    },
    yearForward2() {
      let yearEnd = this.yearEndSelection;
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1);
        }
        this.yearStartSelection += 1;
        this.yearEndSelection = yearEnd + 1;
        this.customerDbRevenue();
      }
    },
    yearBack1921() {
      let yearStart = this.yearStartSelection;
      if (yearStart != this.getMinYear) {
        if (this.getSelectedYear > this.yearEndSelection - 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear - 1);
        }
        this.yearStartSelection = yearStart - 1;
        this.yearEndSelection -= 1;
        this.customerDbRevenue2();
      }
    },
    yearForward1921() {
      let yearEnd = this.yearEndSelection;
      if (yearEnd != this.getCurrentYear) {
        if (this.getSelectedYear < this.yearStartSelection + 1) {
          this.$store.dispatch('setSelectedYear', this.getSelectedYear + 1);
        }
        this.yearStartSelection += 1;
        this.yearEndSelection = yearEnd + 1;
        this.customerDbRevenue2();
      }
    }
  },
  watch: {
    fiveYearDataArray() {
      this.updateChart();
      this.updateChart2();
      this.updateChart1921();
    },
    getYearStartAndgetYearEnd() {
      this.yearStartSelection = this.getYearStart;
      this.yearEndSelection = this.getYearEnd;
    }
  }
};